import { useLocation, Link } from 'react-router-dom';

import { RiHomeSmile2Line } from "react-icons/ri";
import { BsClipboardData } from "react-icons/bs";
import { PiNotebook } from "react-icons/pi";
import { RiCheckboxMultipleLine } from "react-icons/ri";
import { GoPeople } from "react-icons/go";
import { SlGraduation } from "react-icons/sl";
import { BsTranslate } from "react-icons/bs";
import { FaBookBookmark } from "react-icons/fa6";
import { PiSpiral } from "react-icons/pi";
import { API_BASE_URL } from '../config';
import axios from 'axios';

import logo from '../img/logo-taxupdate.png';
import {useEffect, useState} from "react";

function SideMenu() {
    const location = useLocation();
    const path = location.pathname;
    const [produtoAtivo, setProdutoAtivo] = useState('');

    useEffect(() => {
        const storedProdutoAtivo = localStorage.getItem('produto_ativo');
        if (storedProdutoAtivo) {
            setProdutoAtivo(storedProdutoAtivo);
        }
    }, []);

    const handleAlterProduct = () => {
        const token = localStorage.getItem('token');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_BASE_URL}/products/alterProduct`,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        axios.request(config)
            .then((response) => {
                const novoProdutoAtivo = response.data.cache.empresa.produto_ativo;
                setProdutoAtivo(novoProdutoAtivo);
                localStorage.setItem('produto_ativo', novoProdutoAtivo);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            });
    };

    return (
        <nav className="navbar-default navbar-static-side" role="navigation">
            <div className="sidebar-collapse">
                <div className="d-flex justify-content-center mt-4">
                    <img
                        src={logo}
                        alt="Imagem decorativa"
                        style={{width: '101px', height: 'auto'}}
                    />
                </div>

                <ul className="nav metismenu pl-2" id="side-menu" style={{margin: '8rem 0 0 0'}}>
                    <li className={path === "/dashboard" ? 'active mb-2' : 'mb-2'} key="/dashboard">
                        <Link to="/dashboard" style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '3px 0 3px 25px',
                            fontWeight: '500'
                        }}>
                            <div className="text-white mr-3" style={{fontSize: '20px'}}><RiHomeSmile2Line/></div>
                            Dashboard
                        </Link>
                    </li>
                    <li className={path === "/biblioteca" ? 'active mb-2' : 'mb-2'} key="/biblioteca">
                        <Link to="/biblioteca" style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '3px 0 3px 25px',
                            fontWeight: '500'
                        }}>
                            <div className="text-white mr-3" style={{fontSize: '20px'}}><BsClipboardData/></div>
                            Biblioteca
                        </Link>
                    </li>
                    <li className={path === "/calendario" ? 'active mb-2' : 'mb-2'} key="/calendario">
                        <Link to="/calendario" style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '3px 0 3px 25px',
                            fontWeight: '500'
                        }}>
                            <div className="text-white mr-3" style={{fontSize: '20px'}}><PiNotebook/></div>
                            Calendário
                        </Link>
                    </li>
                    <li className={path === "/data" ? 'active mb-2' : 'mb-2'} key="/data">
                        <Link to="/data" style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '3px 0 3px 25px',
                            fontWeight: '500'
                        }}>
                            <div className="text-white mr-3" style={{fontSize: '20px'}}><RiCheckboxMultipleLine/></div>
                            Dados Estatísticos
                        </Link>
                    </li>
                    <li className={path === "/apresentacoes" ? 'active mb-2' : 'mb-2'} key="/apresentacoes">
                        <Link to="/apresentacoes" style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '3px 0 3px 25px',
                            fontWeight: '500'
                        }}>
                            <div className="text-white mr-3" style={{fontSize: '20px'}}><GoPeople/></div>
                            Apresentações Mensais
                        </Link>
                    </li>
                    <li className={path === "#" ? 'active mb-2' : 'mb-2'} key="#">
                        <Link to="#" style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '3px 0 3px 25px',
                            fontWeight: '500'
                        }}>
                            <div className="text-white mr-3" style={{fontSize: '20px'}}><SlGraduation/></div>
                            Jurisprudência
                        </Link>
                    </li>
                    <li className={path === "/leis" ? 'active mb-2' : 'mb-2'} key="/leis">
                        <Link to="/leis" style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '3px 0 3px 25px',
                            fontWeight: '500'
                        }}>
                            <div className="text-white mr-3" style={{fontSize: '20px'}}><BsTranslate/></div>
                            Leis Traduzidas
                        </Link>
                    </li>
                    <li className={path === "/leituras" ? 'active mb-2' : 'mb-2'} key="/leituras">
                        <Link to="/leituras" style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '3px 0 3px 25px',
                            fontWeight: '500'
                        }}>
                            <div className="text-white mr-3" style={{fontSize: '20px'}}><FaBookBookmark/></div>
                            Leituras Técnicas
                        </Link>
                        <ul className="nav nav-second-level collapse">
                            <li><Link href="#">Roteiros</Link></li>
                            <li><Link href="#">Artigos</Link></li>
                            <li><Link href="#">E-Books</Link></li>
                        </ul>
                    </li>
                    <li className={path === "#" ? 'active mb-2' : 'mb-2'} key="#">
                        <Link to="#" style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '3px 0 3px 25px',
                            fontWeight: '500'
                        }}>
                            <div className="text-white mr-3" style={{fontSize: '20px'}}><PiSpiral/></div>
                            Suporte
                        </Link>
                    </li>
                </ul>
                <div
                    className="text-center py-1"
                    style={{
                        borderRadius: '5px',
                        fontSize: '16px',
                        fontWeight: '600',
                        margin: '8rem 2.5% 0 2.5%',
                        backgroundColor: '#F4C70F',
                        color: '#043A5E',
                        width: '90%',
                        cursor: 'pointer'
                    }}
                    onClick={handleAlterProduct}
                >
                    {produtoAtivo === 'brasil' ? 'TaxUpdate LATAM' : 'TaxUpdate Brasil'}
                </div>
            </div>
        </nav>
    );
}

export default SideMenu;