import { useState, useEffect } from "react";
import axios from 'axios';
import { API_BASE_URL } from '../../config';

function Geral() {
    const styles = {
        inputContainer: { position: 'relative', width: '100%' },
        input: { width: '100%', padding: '20px 10px 10px 10px', fontSize: '14px', border: '1px solid #ccc', borderRadius: '4px', outline: 'none' },
        label: { position: 'absolute', top: '10px', left: '10px', color: '#aaa', fontSize: '10px', pointerEvents: 'none', transform: 'translateY(-50%)', transition: '0.2s ease all' },
    };

    const [userData, setUserData] = useState({
        nome: '',
        email: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch user data when component mounts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("token");

                const response = await axios.get(`${API_BASE_URL}/minha-conta`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                setUserData({
                    nome: response.data.nome,
                    email: response.data.email
                });
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle form submission (Updated)
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("token");

            const config = {
                method: 'put',
                url: 'https://cliente-api.taxupdate.com.br/minha-conta-update',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: userData
            };

            const response = await axios.request(config);

            alert('Dados atualizados com sucesso!');
        } catch (err) {
            setError(err);
            alert('Erro ao atualizar os dados.');
        }
    };

    if (loading) {
        return <div>Carregando...</div>;
    }

    if (error) {
        return <div>Erro: {error.message}</div>;
    }

    return (
        <div className="p-4 bg-white shadow-sm d-flex justify-content-between" style={{ borderRadius: '1rem' }}>
            <div style={{ fontSize: '14px', fontWeight: '600' }}>Detalhes</div>
            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-start" style={{ width: '50%' }}>
                <div className="d-flex align-items-center">
                    <div className="mr-4" style={{ width: '100px', height: '100px', border: '3px dotted #D1D6DA', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }}>
                        <img src="/path/to/your/image.jpg" alt="profile" />
                    </div>
                    <div>Alterar</div>
                </div>
                <div className="mt-4 d-flex align-items-center w-100" style={{ position: 'relative' }}>
                    <div style={styles.inputContainer}>
                        <input
                            type="text"
                            id="nome"
                            name="nome"
                            value={userData.nome}
                            onChange={handleChange}
                            style={styles.input}
                        />
                        <label htmlFor="nome" style={styles.label}>
                            Nome Completo
                        </label>
                    </div>
                    <div className="custom-normal" style={{ position: 'absolute', right: '-5rem' }}>
                        <button type="submit" className="btn btn-success">
                            Salvar
                        </button>
                    </div>
                </div>
                <div className="mt-4 d-flex align-items-center w-100">
                    <div style={styles.inputContainer}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={userData.email}
                            onChange={handleChange}
                            style={styles.input}
                        />
                        <label htmlFor="email" style={styles.label}>
                            Email
                        </label>
                    </div>
                </div>
            </form>
            <div></div>
        </div>
    );
}

export default Geral;
