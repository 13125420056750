import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import SideMenu from '../components/SideMenu';
import Navbar from '../components/Navbar';
import { API_BASE_URL } from '../config';

// Estilos para o modal
const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000
    },
    content: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '400px',
        maxWidth: '90%',
        position: 'relative'
    },
    closeBtn: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '18px',
        border: 'none',
        background: 'none'
    }
};

function Calendario() {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async () => {
            if (!token) {
                navigate('/');
                return;
            }
        };
        verifyToken();
    }, [navigate]);

    const styles = {
        selectContainer: { position: 'relative', width: '100%', margin: '0 50px 0 0' },
        select: { width: '100%', padding: '20px 10px 10px 10px', fontSize: '12px', border: '1px solid #E5E7EB', borderRadius: '4px', outline: 'none', backgroundColor: '#fff' },
        label: { position: 'absolute', top: '10px', left: '10px', color: '#aaa', fontSize: '10px', pointerEvents: 'none', transform: 'translateY(-50%)', transition: '0.2s ease all' },
    };

    const calendar_styles = {
        calendarContainer: { display: 'flex', flexDirection: 'column', margin: '0 auto' },
        weekDaysContainer: { display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', fontWeight: 'bold', textAlign: 'center' },
        weekDay: { padding: '10px' },
        daysContainer: { display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gridTemplateRows: 'repeat(6, 1fr)' },
        dayBlock: { position: 'relative', width: '100%', padding: '55px', backgroundColor: '#fff', textAlign: 'center' },
        dayNumber: { position: 'absolute', top: '10px', right: '10px', fontSize: '14px' },
        eventsContainer: { position: 'absolute', bottom: '10px', left: '0px', right: '0px', textAlign: 'left', fontSize: '12px' },
        event: { fontSize: '12px', width: '100%', backgroundColor: '#449A86', color: '#fff', paddingLeft: '10px', borderRadius: '5px', fontWeight: '600', marginBottom: '2px' },
    };

    const [year] = useState(2024); // Ano fixo
    const [month, setMonth] = useState(new Date().getMonth()); // 0-11
    const [eventos, setEventos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Estados para o modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [areaatuacaoId, setAreaatuacaoId] = useState('');
    const [descricao, setDescricao] = useState('');
    const [dataCompromisso, setDataCompromisso] = useState('');
    const [areasDeAtuacao, setAreasDeAtuacao] = useState([]);
    const [loadingAreas, setLoadingAreas] = useState(false);
    const [errorAreas, setErrorAreas] = useState(null);

    useEffect(() => {
        const fetchAreasDeAtuacao = async () => {
            setLoadingAreas(true);
            setErrorAreas(null);
            try {
                const headers = {
                    'Authorization': 'Bearer ' + token
                };

                // Buscar estados
                const estadosResponse = await axios.get(`${API_BASE_URL}/filtros/estados`, { headers });
                const estados = estadosResponse.data;

                // Buscar municípios
                const municipiosResponse = await axios.get(`${API_BASE_URL}/filtros/municipios`, { headers });
                const municipios = municipiosResponse.data;

                // Buscar municípios
                const federalResponse = await axios.get(`${API_BASE_URL}/filtros/federal`, { headers });
                const federal = federalResponse.data;

                // Combinar as duas listas
                const combinedAreas = [...estados, ...municipios, ...federal];

                // Mapear para incluir apenas id e nome
                const areasList = combinedAreas.map(area => ({
                    id: area.areaatuacao_id,
                    nome: area.nome
                }));

                setAreasDeAtuacao(areasList);
            } catch (err) {
                console.error(err);
                setErrorAreas('Erro ao carregar áreas de atuação');
            } finally {
                setLoadingAreas(false);
            }
        };

        fetchAreasDeAtuacao();
    }, [token]);

    const allMonths = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

    const today = new Date();
    const todayDate = today.getDate();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const generateCalendar = (year, month) => {
        const daysInCurrentMonth = getDaysInMonth(year, month);
        const firstDayOfMonth = new Date(year, month, 1).getDay();

        let daysArray = [];

        if (firstDayOfMonth > 0) {
            const prevMonth = month === 0 ? 11 : month - 1;
            const prevYear = month === 0 ? year - 1 : year;
            const prevMonthDays = getDaysInMonth(prevYear, prevMonth);
            for (let i = firstDayOfMonth - 1; i >= 0; i--) {
                daysArray.push({ day: prevMonthDays - i, isCurrentMonth: false });
            }
        }

        for (let i = 1; i <= daysInCurrentMonth; i++) {
            daysArray.push({ day: i, isCurrentMonth: true });
        }

        while (daysArray.length < 42) {
            const nextMonthDay = daysArray.length - daysInCurrentMonth - firstDayOfMonth + 1;
            daysArray.push({ day: nextMonthDay, isCurrentMonth: false });
        }

        return daysArray;
    };

    const calendarDays = generateCalendar(year, month);
    const isLastColumn = (index) => (index + 1) % 7 === 0;

    const getEventsForDay = (day) => {
        return eventos.filter((event) => {
            const eventDate = new Date(event.start);
            return eventDate.getDate() === day && eventDate.getMonth() === month && eventDate.getFullYear() === year;
        });
    };

    useEffect(() => {
        const fetchEventos = async () => {
            setLoading(true);
            setError(null);
            try {
                const formattedMonth = String(month + 1).padStart(2, '0'); // Mês em formato MM
                const response = await axios.get(`${API_BASE_URL}/calendario?mes=${formattedMonth}/${year}`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });
                setEventos(response.data);
            } catch (err) {
                console.error(err);
                setError('Erro ao carregar eventos');
            } finally {
                setLoading(false);
            }
        };

        fetchEventos();
    }, [month, year, isModalOpen]); // Reexecuta sempre que month ou year mudar

    const handlePreviousMonth = () => {
        setMonth((prevMonth) => {
            if (prevMonth === 0) {
                return 11; // Vai para dezembro
            } else {
                return prevMonth - 1;
            }
        });
    };

    const handleNextMonth = () => {
        setMonth((prevMonth) => {
            if (prevMonth === 11) {
                return 0; // Vai para janeiro
            } else {
                return prevMonth + 1;
            }
        });
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const openModalDaySelect = (dateSelect: number) => {
        var monthCompromisso = String(month + 1).padStart(2, '0')
        setDataCompromisso(`${year}-${monthCompromisso}-${dateSelect}`);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        // Resetar campos
        setAreaatuacaoId('');
        setDescricao('');
        setDataCompromisso('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validação básica
        if (!areaatuacaoId || !descricao || !dataCompromisso) {
            alert('Por favor, preencha todos os campos.');
            return;
        }

        const data = JSON.stringify({
            "areaatuacao_id": parseInt(areaatuacaoId),
            "descricao": descricao,
            "data_compromisso": dataCompromisso
        });

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_BASE_URL}/calendario`,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));
            setEventos([...eventos, response.data]);
            closeModal();
        } catch (error) {
            alert('Erro ao adicionar evento.');
        }
    };

    return (
        <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
            <SideMenu />

            <div id="page-wrapper" className="pb-5" style={{ backgroundColor: '#FCFCFD' }}>
                <Navbar />

                <div className="pl-2 mt-4 mb-3 custom-normal" style={{ fontSize: '38px', fontWeight: '600' }}>Calendário</div>

                <div className="d-flex justify-content-between align-items-center p-4">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="mr-2" style={{ fontSize: '20px', fontWeight: '600', color: '#111927' }}>{allMonths[month]}</div>
                            <div style={{ fontSize: '20px', fontWeight: '400' }}>{year}</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-between align-items-center">
                            <div
                                className="mr-4"
                                style={{ fontSize: '25px', cursor: 'pointer' }}
                                onClick={handlePreviousMonth}
                            >
                                <RiArrowLeftSLine />
                            </div>
                            <div
                                className="mr-4"
                                style={{ fontSize: '25px', cursor: 'pointer' }}
                                onClick={handleNextMonth}
                            >
                                <RiArrowRightSLine />
                            </div>
                        </div>
                        <div
                            className="text-white"
                            style={{ backgroundColor: '#449A86', borderRadius: '16px', fontSize: '14px', padding: '0.7rem 1.2rem', cursor: 'pointer' }}
                            onClick={openModal}
                        >
                            + Novo Evento
                        </div>
                    </div>
                </div>

                <div className="bg-white border-0 shadow-sm" style={{ borderRadius: '1rem' }}>
                    <div style={calendar_styles.calendarContainer}>
                        {/* Dias da Semana */}
                        <div style={calendar_styles.weekDaysContainer}>
                            {daysOfWeek.map((day, index) => (
                                <div
                                    style={{
                                        ...calendar_styles.weekDay,
                                        borderRight: isLastColumn(index) ? 'none' : '1px solid #e0e0e0'
                                    }}
                                    key={day}>
                                    {day}
                                </div>
                            ))}
                        </div>

                        {/* Dias do Mês */}
                        <div style={calendar_styles.daysContainer}>
                            {calendarDays.map((dayObj, index) => {
                                const { day } = dayObj;
                                const events = getEventsForDay(dayObj.day -1);
                                const isToday = dayObj.isCurrentMonth && dayObj.day === todayDate && year === currentYear && month === currentMonth;

                                return (
                                    <div
                                        style={{
                                            ...calendar_styles.dayBlock,
                                            color: dayObj.isCurrentMonth ? '#101927' : '#C6C6C6',
                                            backgroundColor: isToday ? '#FFDEBF' : (dayObj.isCurrentMonth ? '#fff' : '#f0f0f0'),
                                            borderTop: '1px solid #e0e0e0',
                                            borderRight: isLastColumn(index) ? 'none' : '1px solid #e0e0e0',
                                            cursor: dayObj.isCurrentMonth ? 'pointer' : 'default',
                                        }}
                                        key={index}
                                        onClick={() => openModalDaySelect(day)}
                                    >
                                        <span style={calendar_styles.dayNumber}>{dayObj.day}</span>

                                        {events.length > 0 && dayObj.isCurrentMonth && (
                                            <div style={calendar_styles.eventsContainer}>
                                                {events.map((event) => (
                                                    <div key={event.id} style={calendar_styles.event}>
                                                        {event.title}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* Indicadores de Carregamento e Erro */}
                {loading && <p className="text-center mt-3">Carregando eventos...</p>}
                {error && <p className="text-center mt-3 text-danger">{error}</p>}

                {/* Modal para Adicionar Novo Evento */}
                {isModalOpen && (
                    <div style={modalStyles.overlay}>
                        <div style={modalStyles.content}>
                            <button style={modalStyles.closeBtn} onClick={closeModal}>&times;</button>
                            <h2>Adicionar Novo Evento</h2>
                            {loadingAreas ? (
                                <p>Carregando áreas de atuação...</p>
                            ) : errorAreas ? (
                                <p className="text-danger">{errorAreas}</p>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div style={{ marginBottom: '15px' }}>
                                        <label htmlFor="areaatuacao" style={{ display: 'block', marginBottom: '5px' }}>Área de Atuação</label>
                                        <select
                                            id="areaatuacao"
                                            value={areaatuacaoId}
                                            onChange={(e) => setAreaatuacaoId(e.target.value)}
                                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                            required
                                        >
                                            <option value="">Selecione uma área</option>
                                            {areasDeAtuacao.map((area) => (
                                                <option key={area.id} value={area.id}>{area.nome}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <label htmlFor="descricao" style={{ display: 'block', marginBottom: '5px' }}>Descrição</label>
                                        <input
                                            type="text"
                                            id="descricao"
                                            value={descricao}
                                            onChange={(e) => setDescricao(e.target.value)}
                                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                            required
                                        />
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <label htmlFor="data_compromisso" style={{ display: 'block', marginBottom: '5px' }}>Data do Compromisso</label>
                                        <input
                                            type="date"
                                            id="data_compromisso"
                                            value={dataCompromisso}
                                            onChange={(e) => setDataCompromisso(e.target.value)}
                                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                            required
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            backgroundColor: '#449A86',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            fontSize: '16px'
                                        }}
                                    >
                                        Adicionar Evento
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Calendario;
