import React, { useEffect, useState } from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa';
import SideMenu from '../../components/SideMenu';
import ChartData from './ChartData';
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import HeatmapExample from "./HeatMap";
import axios from "axios";
import { API_BASE_URL } from '../../config';

function StaticalData() {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [staticDatas, setStaticDatas] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyToken = () => {
            if (!token) {
                navigate('/');
                return;
            }
        };
        verifyToken();
    }, [navigate, token]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            const settings = {
                url: `${API_BASE_URL}/dashboard/dados-estatisticos`,
                method: "GET",
                timeout: 0,
                headers: {
                    "Authorization": "Bearer " + token
                }
            };

            try {
                const response = await axios(settings);
                console.log(response.data);
                setStaticDatas(response.data);
            } catch (error) {
                setError(error);
            }

            setLoading(false);
        };

        fetchData();
    }, [token]);

    if (loading) {
        return (
            <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
                <SideMenu />

                <div id="page-wrapper" style={{ backgroundColor: '#FCFCFD' }}>
                    <Navbar />

                    <div className="pl-4 mt-4 custom-normal" style={{ fontSize: '34px', fontWeight: '600' }}>Dados Estatísticos</div>

                    <div className="wrapper wrapper-content">
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <FaSpinner
                                className="spinner"
                                style={{ fontSize: '24px', animation: 'spin 1s linear infinite' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div id="wrapper" style={{backgroundColor: '#043A5E'}}>
                <SideMenu/>

                <div id="page-wrapper" style={{backgroundColor: '#FCFCFD'}}>
                    <Navbar/>

                    <div className="pl-4 mt-4 custom-normal" style={{fontSize: '34px', fontWeight: '600'}}>Dados
                        Estatísticos
                    </div>

                    <div className="wrapper wrapper-content">
                        <div style={{textAlign: 'center', padding: '20px'}}>
                            Erro: {error.message}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="wrapper" style={{backgroundColor: '#043A5E'}}>
            <SideMenu/>

            <div id="page-wrapper" style={{backgroundColor: '#FCFCFD'}}>
                <Navbar/>

                <div class="pl-4 mt-4 custom-normal" style={{fontSize: '34px', fontWeight: '600'}}>Dados Estatísticos
                </div>

                <div class="wrapper wrapper-content">
                    <div class="d-flex justify-content-between mb-4">
                        <div style={{borderRadius: '1rem', width: '68%'}}>
                            <div class="p-4 border-0 shadow-sm bg-white" style={{borderRadius: '1rem'}}>
                            <div class="custom-normal" style={{ fontSize: '16px', fontWeight: '600' }}>Normas Publicadas</div>
                                <div class="d-flex justify-content-between mt-4">
                                    <div class="" style={{ width: '25%' }}>
                                        <div class="card p-4 rounded-pill border-0 shadow-sm text-center" style={{ borderRadius: '1rem', background: '#ECF5F3' }}>
                                            <div class="d-flex flex-column align-items-start">
                                                <div class="text-black-50">Ultimos 30 dias</div>
                                                <h2 class="mt-1 custom-normal" style={{ fontSize: '20px', fontWeight: '600' }}>{ staticDatas.normas_ultimos_30_dias }</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style={{ width: '25%' }}>
                                        <div class="card p-4 rounded-pill border-0 shadow-sm text-center" style={{ borderRadius: '1rem', background: '#ECF5F3' }}>
                                            <div class="d-flex flex-column align-items-start">
                                                <div class="text-black-50">Ultimos 90 dias</div>
                                                <h2 class="mt-1 custom-normal" style={{ fontSize: '20px', fontWeight: '600' }}>{ staticDatas.normas_ultimos_90_dias }</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style={{ width: '25%' }}>
                                        <div class="card p-4 rounded-pill border-0 shadow-sm text-center" style={{ borderRadius: '1rem', background: '#ECF5F3' }}>
                                            <div class="d-flex flex-column align-items-start">
                                                <div class="text-black-50">Ultimos 365 dias</div>
                                                <h2 class="mt-1 custom-normal" style={{ fontSize: '20px', fontWeight: '600' }}>{ staticDatas.normas_ultimos_365_dias }</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4 p-4 border-0 shadow-sm bg-white" style={{ borderRadius: '1rem' }}>
                                <div class="custom-normal mb-3" style={{ fontSize: '16px', fontWeight: '600' }}>Publicadas</div>
                                <ChartData />
                            </div>
                        </div>

                        <div style={{ borderRadius: '1rem', width: '30%' }}>
                            <div class="border-0 shadow-sm bg-white d-flex flex-column justify-content-between" style={{ borderRadius: '1rem' }}>
                                <div class="">
                                    <div class="p-3">
                                        <h3>Normas por esfera</h3>
                                    </div>
                                    <div class="bg-light d-flex justify-content-between alignm-items-center" style={{ padding: '0.8rem 0 0.8rem 0' }}>
                                        <div class="pl-3" style={{ width: '70%' }}>FONTE</div>
                                        <div style={{ width: '30%' }}>DATA</div>
                                    </div>
                                    {staticDatas.normas_por_esfera ? staticDatas.normas_por_esfera.map(item => (
                                        <div className="d-flex justify-content-between alignm-items-center"
                                             style={{padding: '0.8rem 0 0.8rem 0'}}>
                                            <div className="pl-3" style={{width: '70%'}}>{item.estado}</div>
                                            <div style={{width: '30%'}}>{item.quantidade}</div>
                                        </div>
                                    )) : (
                                        <div className="d-flex justify-content-between alignm-items-center"
                                             style={{padding: '0.8rem 0 0.8rem 0'}}>
                                            <div className="pl-3" style={{width: '70%'}}></div>
                                            <div style={{width: '30%'}}></div>
                                        </div>
                                    )}
                                </div>
                                <div class="pl-4 mb-3 mt-2 d-flex">
                                    <div>Ver mais</div>
                                    <div class="ml-1 d-flex justify-content-start align-items-center" style={{ fontSize: '1.3rem' }}><IoIosArrowRoundForward /></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="d-flex justify-content-between mb-4">
                        <div style={{ borderRadius: '1rem', width: '68%' }}>
                            <div class="mt-4 p-4 border-0 shadow-sm bg-white" style={{ borderRadius: '1rem' }}>
                                <div class="custom-normal" style={{ fontSize: '16px', fontWeight: '600' }}>Por estado</div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex justify-content-center"
                                         style={{width: '59%', height: '400px'}}>
                                        <HeatmapExample normasPorEsfera={staticDatas.normas_por_estado}/>
                                    </div>

                                    <div style={{width: '35%'}}>
                                        <div className="text-black-50">Total</div>
                                        <h2 class="mt-1 custom-normal"
                                            style={{fontSize: '20px', fontWeight: '600'}}>{staticDatas.total_normas_por_estado}</h2>

                                        {staticDatas.normas_por_estado ? staticDatas.normas_por_estado.slice(0, 5).map(item => (
                                            <div className="d-flex align-items-center mt-4">
                                                <div className="mr-3" style={{
                                                    width: '45px',
                                                    height: '45px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#E5E7EB'
                                                }}>
                                                    <img src="/path/to/your/image.jpg" alt=""
                                                         style={{width: '45px', height: '45px', borderRadius: '50%'}}/>
                                                </div>
                                                <div style={{width: '75%'}}>
                                                    <div className="custom-normal mb-2">{item.estado}</div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="progress-bar-container">
                                                            <div className="progress-bar" style={{width: `${item.porcentagem}%`}}>
                                                                <span style={{padding: '0 10px 0 0'}}></span>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            fontSize: '15px',
                                                            fontWeight: '500',
                                                            margin: '0 0 0 1rem'
                                                        }}>{item.porcentagem}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : (
                                            <div className="d-flex align-items-center mt-4">
                                                <div className="mr-3" style={{
                                                    width: '45px',
                                                    height: '45px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#E5E7EB'
                                                }}>
                                                    <img src="/path/to/your/image.jpg" alt=""
                                                         style={{width: '45px', height: '45px', borderRadius: '50%'}}/>
                                                </div>
                                                <div style={{width: '75%'}}>
                                                    <div className="custom-normal mb-2">Brasil</div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="progress-bar-container">
                                                            <div className="progress-bar" style={{width: `${0}%`}}>
                                                                <span style={{padding: '0 10px 0 0'}}></span>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            fontSize: '15px',
                                                            fontWeight: '500',
                                                            margin: '0 0 0 1rem'
                                                        }}>0%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div style={{ borderRadius: '1rem', width: '30%' }}>*/}
                        {/*    <div class="mt-4 border-0 shadow-sm bg-white d-flex flex-column justify-content-between" style={{ borderRadius: '1rem' }}>*/}
                        {/*        <div class="">*/}
                        {/*            <div class="p-3 mb-4">*/}
                        {/*                <h3>Placeholder</h3>*/}
                        {/*                <div class="font-weight-light">Teste</div>*/}
                        {/*            </div>*/}
                        {/*            <DonutData />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StaticalData;
