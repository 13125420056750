import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";
import { BsDownload } from "react-icons/bs";
import { LuArrowRight } from "react-icons/lu";
import { CiSearch  } from "react-icons/ci";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { FaSpinner } from 'react-icons/fa';

import axios from 'axios';
import {useNavigate} from "react-router-dom";
import { API_BASE_URL } from '../config';

function Datatable({ dataDe, dataAte }) {
    const styles = {
        selectContainer: { position: 'relative', width: '100%' },
        select: { width: '100%', padding: '20px 10px 10px 10px', fontSize: '12px', border: '1px solid #E5E7EB', borderRadius: '4px', outline: 'none', backgroundColor: '#fff' },
        label: { position: 'absolute', top: '10px', left: '10px', color: '#aaa', fontSize: '10px', pointerEvents: 'none', transform: 'translateY(-50%)', transition: '0.2s ease all' },
    };

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    const handleSelect = (id) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(item => item !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allIds = data.map(item => item.id);
            setSelectedItems(allIds);
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const token = localStorage.getItem("token");

                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${API_BASE_URL}/apresentacoes`,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        pagina: page,
                        limite_quantidade: itemsPerPage,
                    }
                };

                if (dataDe) {
                    config.params.data_de = dataDe;
                }
                if (dataAte) {
                    config.params.data_ate = dataAte;
                }
                if (searchQuery) {
                    config.params.search = searchQuery;
                }

                const response = await axios.request(config);

                const fetchedData = response.data.newsletters || [];
                setData(fetchedData);

                // Determine if there's more data
                setHasMore(fetchedData.length === itemsPerPage);

            } catch (error) {
                setError(error);
            }

            setLoading(false);
        };

        fetchData();
    }, [dataDe, dataAte, page, itemsPerPage, searchQuery]);

    const startItem = page * itemsPerPage + 1;
    const endItem = startItem + data.length - 1;

    return (
        <div>
            <div className="p-4 d-flex justify-content-between align-items-center" style={{ fontSize: '14px', fontWeight: '600' }}>

                <div className="d-flex align-items-center" style={{ position: 'relative', width: '75%' }}>
                    <CiSearch style={{ position: 'absolute', left: '10px', color: '#232730', fontSize: '24px' }} />
                    <input
                        type="text"
                        placeholder="Procurar"
                        className="input-pesquisa"
                        style={{ padding: '15px 10px 15px 40px' }}
                        value={searchQuery}
                        onChange={(e) => { setSearchQuery(e.target.value); setPage(0); }}
                    />
                </div>

                <div style={{ width: '20%' }}>
                    <div style={styles.selectContainer}>
                        <select id="orderBy" name="orderBy" defaultValue="Mais recente" style={styles.select}>
                            <option>Mais recente</option>
                        </select>
                        <label htmlFor="orderBy" style={styles.label}>
                            Ordenar por
                        </label>
                    </div>
                </div>

            </div>

            <div className="mb-3">
                <div className="d-flex justify-content-between py-3 px-3 bg-light" style={{ fontSize: '13px', fontWeight: '600' }}>
                    <div className="d-flex justify-content-start" style={{ width: '5%' }}>
                        <ul className="d-flex justify-content-start align-items-center" style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                            <li>
                                <label className="d-flex justify-content-start align-items-center" style={{ margin: '0' }}>
                                    <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                    <span className="custom_checkbox_library"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div style={{ width: '85%' }}>RELATÓRIOS</div>
                    <div style={{ width: '10%', textAlign: 'end' }}>AÇÕES</div>
                </div>

                {loading && (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <FaSpinner className="spinner" />
                    </div>
                )}
                {error && (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        Erro: {error.message}
                    </div>
                )}
                {!loading && !error && data && data.length > 0 ? data.map(item => (
                    <div className="d-flex justify-content-between py-3 px-3" key={item.id} style={{ fontSize: '13px', fontWeight: '600', borderBottom: '1px solid #F3F4F7' }}>
                        <div className="d-flex justify-content-start" style={{ width: '5%' }}>
                            <ul className="d-flex justify-content-start align-items-center" style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                <li>
                                    <label className="d-flex justify-content-start align-items-center" style={{ margin: '0' }}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={selectedItems.includes(item.id)}
                                            onChange={() => handleSelect(item.id)}
                                        />
                                        <span className="custom_checkbox_library"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: '90%' }}>
                            {/* Assuming the 'enviado' field contains the date */}
                            <div className="mr-3 d-flex flex-column justify-content-center align-items-center" style={{ width: '60px', height: '60px', borderRadius: '15px', backgroundColor: '#E5E7EB' }}>
                                <div style={{ fontSize: '14px', fontWeight: '600' }}>{new Date(item.enviado).toLocaleString('default', { month: 'short' }).toUpperCase()}</div>
                                <div style={{ fontSize: '15px', fontWeight: '700' }}>{new Date(item.enviado).getDate()}</div>
                            </div>
                            <div>
                                <div>{item.assunto}</div>
                                <div>{new Date(item.enviado).toLocaleDateString()}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: '5%', textAlign: 'end', fontSize: '22px' }}>
                            <div className="mr-3"><BsDownload /></div>
                            <div><LuArrowRight /></div>
                        </div>
                    </div>
                )) : !loading && <div style={{ textAlign: 'center', padding: '20px' }}>Nenhum dado disponível.</div>}

                <div className="d-flex justify-content-end align-items-center py-2">
                    <div className="mr-4">Documentos por página:</div>
                    <select className="mr-4" style={{ border: 'none' }} value={itemsPerPage} onChange={(e) => { setItemsPerPage(parseInt(e.target.value)); setPage(0); }}>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                    </select>
                    <div className="mr-4">Mostrando {startItem}-{endItem}</div>
                    <div
                        className="mr-4"
                        style={{ fontSize: '20px', cursor: page === 0 ? 'default' : 'pointer', opacity: page === 0 ? 0.5 : 1 }}
                        onClick={() => {
                            if (page > 0) {
                                setPage(page - 1);
                            }
                        }}
                    >
                        <RiArrowLeftSLine />
                    </div>
                    <div
                        className="mr-4"
                        style={{ fontSize: '20px', cursor: hasMore ? 'pointer' : 'default', opacity: hasMore ? 1 : 0.5 }}
                        onClick={() => {
                            if (hasMore) {
                                setPage(page + 1);
                            }
                        }}
                    >
                        <RiArrowRightSLine />
                    </div>
                </div>
            </div>
        </div>
    );
}

function Apresentacoes() {
    const [activeMenu, setActiveMenu] = useState('Todos');
    const tokenValidate = localStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async () => {
            if (!tokenValidate) {
                navigate('/');
                return;
            }
        };
        verifyToken();
    }, [navigate]);

    const renderContent = () => {
        let dataDe = null;
        let dataAte = null;
        const today = new Date();

        switch (activeMenu) {
            case 'Todos':
                dataDe = new Date();
                dataDe.setDate(dataDe.getDate() - 1825);
                dataAte = today;
                break;
            case '90 dias':
                dataDe = new Date();
                dataDe.setDate(dataDe.getDate() - 90);
                dataAte = today;
                break;
            case '180 dias':
                dataDe = new Date();
                dataDe.setDate(dataDe.getDate() - 180);
                dataAte = today;
                break;
            case 'Último ano':
                dataDe = new Date();
                dataDe.setFullYear(dataDe.getFullYear() - 1);
                dataAte = today;
                break;
            default:
                break;
        }

        const formatDate = (date) => date.toISOString().split('T')[0];

        return <Datatable dataDe={formatDate(dataDe)} dataAte={formatDate(dataAte)} />;
    };

    return (
        <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
            <SideMenu />

            <div id="page-wrapper" style={{ backgroundColor: '#FCFCFD' }}>
                <Navbar />

                <div className="pl-2 mt-4 mb-4 custom-normal" style={{ fontSize: '38px', fontWeight: '600' }}>Apresentações Mensais</div>

                <div className="bg-white shadow-sm d-flex flex-column justify-content-between" style={{ borderRadius: '1rem' }}>
                    <div>
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <ul className="nav nav-pills w-100" style={{ borderBottom: '1px solid #F3F4F7' }}>
                                {['Todos', '90 dias', '180 dias', 'Último ano'].map((menu) => (
                                    <li key={menu}>
                                        <div
                                            className={`mr-5 ${activeMenu === menu ? 'active-menu' : ''}`}
                                            style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                            onClick={() => setActiveMenu(menu)}
                                        >
                                            {menu}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <div className="content" style={{ marginTop: '20px' }}>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Apresentacoes;
