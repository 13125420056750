import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from '../config';

import { BsDownload } from "react-icons/bs";
import { LuArrowRight } from "react-icons/lu";
import { CiSearch } from "react-icons/ci";
import { IoMdCalendar } from "react-icons/io";
import Select from 'react-select'

import SideMenu from '../components/SideMenu';
import Navbar from '../components/Navbar';
import { FaSpinner } from "react-icons/fa";

function Switch({ isOn, toggleSwitch }) {
    const styles = {
        switchContainer: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
        switch: { width: '50px', height: '16px', backgroundColor: isOn ? '#809DAF' : '#ccc', borderRadius: '25px', position: 'relative', transition: 'background-color 0.3s' },
        switchToggle: { width: '23px', height: '23px', backgroundColor: '#043A5E', borderRadius: '50%', position: 'absolute', top: '-4px', left: isOn ? '26px' : '1px', transition: 'left 0.3s' },
        switchLabel: { marginLeft: '10px', fontSize: '14px' },
    };

    return (
        <div style={styles.switchContainer} onClick={toggleSwitch}>
            <div style={styles.switch}>
                <div style={styles.switchToggle}></div>
            </div>
            <span style={styles.switchLabel}>{'Normas informadas'}</span>
        </div>
    );
}

function Estados({ estadosData, estadosSelecionados, setEstadosSelecionados }) {
    const options = estadosData ? estadosData.map(estado => ({
        label: `${estado.nome} - ${estado.descricao}`,
        value: estado.areaatuacao_id
    })) : [];

    const selectedOptions = options.filter(option => estadosSelecionados.includes(option.value));

    const handleChange = (selectedOptions) => {
        setEstadosSelecionados(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    return (
        <div className="mt-4">
            <div className="mb-2">Estado</div>
            <Select
                value={selectedOptions}
                isMulti
                name="estados"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChange}
                placeholder="Selecione os estados..."
                noOptionsMessage={() => "Nenhum estado disponível"}
            />
        </div>
    );
}

function Municipio({ municipiosData, municipiosSelecionados, setMunicipiosSelecionados }) {
    const options = municipiosData ? municipiosData.map(municipio => ({
        label: `${municipio.nome}`,
        value: municipio.areaatuacao_id
    })) : [];

    const selectedOptions = options.filter(option => municipiosSelecionados.includes(option.value));

    const handleChange = (selectedOptions) => {
        setMunicipiosSelecionados(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    return (
        <div className="mt-4">
            <div className="mb-2">Municipio</div>
            <Select
                value={selectedOptions}
                isMulti
                name="estados"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChange}
                placeholder="Selecione os municipios..."
                noOptionsMessage={() => "Nenhum municipio disponível"}
            />
        </div>
    );
}

function Impostos({ impostosData, impostosSelecionados, setImpostosSelecionados }) {
    const options = impostosData ? impostosData.map(imposto => ({
        label: `${imposto.nome}`,
        value: imposto.areaatuacao_id
    })) : [];

    const selectedOptions = options.filter(option => impostosSelecionados.includes(option.value));

    const handleChange = (selectedOptions) => {
        setImpostosSelecionados(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    return (
        <div className="mt-4">
            <div className="mb-2">Tipos de Impostos</div>
            <Select
                value={selectedOptions}
                isMulti
                name="impostos"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChange}
                placeholder="Selecione o imposto..."
                noOptionsMessage={() => "Nenhum imposto disponível"}
            />
        </div>
    );
}

function Biblioteca() {
    const navigate = useNavigate();
    const [normasData, setNormasData] = useState(null);
    const [estadosData, setEstadosData] = useState(null);
    const [municipiosData, setMunicipiosData] = useState(null);
    const [impostosData, setImpostosData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');
    const [expandedId, setExpandedId] = useState(null);

    // Estados para filtros
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [estadosSelecionados, setEstadosSelecionados] = useState([]);
    const [municipiosSelecionados, setMunicipiosSelecionados] = useState([]);
    const [impostosSelecionados, setImpostosSelecionados] = useState([]);
    const [normasNaoInformadas, setNormasNaoInformadas] = useState(1);

    const handleClick = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    useEffect(() => {
        const verifyToken = async () => {
            if (!token) {
                navigate('/');
                return;
            }
        };
        verifyToken();
    }, [navigate, token]);

    // Defina a função fetchData fora do useEffect
    const fetchData = async () => {
        setLoading(true);
        setError(null);

        const page = 0;
        const limitePage = 35;

        const params = {
            pagina: page,
            limite_quantidade: limitePage,
            normas_nao_informadas: normasNaoInformadas,
        };

        if (dateStart) {
            params.data_de = dateStart;
        }
        if (dateEnd) {
            params.data_ate = dateEnd;
        }
        if (searchQuery) {
            params.search = searchQuery;
        }
        if (estadosSelecionados.length > 0) {
            params.estados = estadosSelecionados.join(',');
        }
        if (municipiosSelecionados.length > 0) {
            params.municipios = municipiosSelecionados.join(',');
        }
        if (impostosSelecionados.length > 0) {
            params.federal = impostosSelecionados.join(',');
        }

        const normasSettings = {
            url: `${API_BASE_URL}/dashboard/normas`,
            method: "GET",
            timeout: 0,
            headers: {
                "Authorization": "Bearer " + token
            },
            params: params
        };

        try {
            const normasResponse = await axios(normasSettings);
            setNormasData(normasResponse.data);
        } catch (error) {
            setError(error);
        }

        const estadosSettings = {
            url: `${API_BASE_URL}/filtros/estados`,
            method: "GET",
            timeout: 0,
            headers: {
                "Authorization": "Bearer " + token
            }
        };
        try {
            const estadosResponse = await axios(estadosSettings);
            setEstadosData(estadosResponse.data);
        } catch (error) {
            setError(error);
        }

        const municipiosSettings = {
            url: `${API_BASE_URL}/filtros/municipios`,
            method: "GET",
            timeout: 0,
            headers: {
                "Authorization": "Bearer " + token
            }
        };
        try {
            const municipiosResponse = await axios(municipiosSettings);
            setMunicipiosData(municipiosResponse.data);
        } catch (error) {
            setError(error);
        }

        const immpostosSettings = {
            url: `${API_BASE_URL}/filtros/federal`,
            method: "GET",
            timeout: 0,
            headers: {
                "Authorization": "Bearer " + token
            }
        };
        try {
            const impostosResponse = await axios(immpostosSettings);
            setImpostosData(impostosResponse.data);
        } catch (error) {
            setError(error);
        }

        setLoading(false);
    };

    // useEffect principal sem o searchQuery
    useEffect(() => {
        fetchData();
    }, [token, dateStart, dateEnd, estadosSelecionados, municipiosSelecionados, impostosSelecionados, normasNaoInformadas]);

    // useEffect para debounce do searchQuery
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchData();
        }, 500); // 500ms de atraso

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery]);

    const formatDate = (dateString) => {
        const [datePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-');
        return `${day}/${month}/${year}`;
    };

    const toggleNormasNaoInformadas = () => {
        setNormasNaoInformadas(normasNaoInformadas === 1 ? 0 : 1);
    };

    const NormaLabel = ({ areaAtuacaoNome }) => {
        const renderLabel = () => {
            if (areaAtuacaoNome === "UNIÃO") {
                return (
                    <div className="py-1 text-center" style={{
                        backgroundColor: '#E2F7F1',
                        color: '#08815B',
                        borderRadius: '1rem',
                        fontWeight: '600',
                        width: '85px',
                        letterSpacing: '0.7px'
                    }}>FEDERAL</div>
                );
            } else if (areaAtuacaoNome.length === 2) {
                return (
                    <div className="py-1 text-center" style={{
                        backgroundColor: '#E2EBF7',
                        color: '#043A5F',
                        borderRadius: '1rem',
                        fontWeight: '600',
                        width: '85px',
                        letterSpacing: '0.5px'
                    }}>ESTADUAL</div>
                );
            } else if (areaAtuacaoNome.length > 4) {
                return (
                    <div className="py-1 text-center" style={{
                        backgroundColor: '#F6E9E1',
                        color: '#E9742E',
                        borderRadius: '1rem',
                        fontWeight: '600',
                        width: '85px'
                    }}>MUNICIPAL</div>
                );
            } else {
                return (
                    <div className="py-1 text-center" style={{
                        backgroundColor: '#e1f4f6',
                        color: '#2ec7e9',
                        borderRadius: '1rem',
                        fontWeight: '600',
                        width: '85px'
                    }}>{areaAtuacaoNome}</div>
                );
            }
            return null;
        };
        return renderLabel();
    };

    return (
        <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
            <SideMenu />

            <div id="page-wrapper" style={{ backgroundColor: '#FCFCFD' }}>
                <Navbar />

                <div className="wrapper wrapper-content">
                    <div className="d-flex justify-content-between">

                        {/* FILTROS */}
                        <div className="border-0 shadow-sm bg-white p-4" style={{ borderRadius: '1rem', width: '25%' }}>
                            <div>
                                <div className="mb-4 custom-normal" style={{ fontSize: '25px', fontWeight: '600' }}>Filtros</div>
                                <div>
                                    <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                                        <CiSearch style={{
                                            position: 'absolute',
                                            left: '10px',
                                            color: '#232730',
                                            fontSize: '24px'
                                        }} />
                                        <input
                                            type="text"
                                            placeholder="Palavras Chave"
                                            className="input-pesquisa"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>

                                    {/* ON e OFF */}
                                    <div className="mb-4 mt-4">
                                        <Switch
                                            isOn={normasNaoInformadas === 0}
                                            toggleSwitch={toggleNormasNaoInformadas}
                                        />
                                    </div>

                                    <div className="form">
                                        <label className="control-label mt-2">Data de Publicação</label>
                                        <div>
                                            <div style={{position: 'relative'}}>
                                                <input
                                                    type="date"
                                                    placeholder="De"
                                                    value={dateStart}
                                                    onChange={(e) => setDateStart(e.target.value)}
                                                    style={{
                                                        width: '100%',
                                                        padding: '13px',
                                                        paddingRight: '35px',
                                                        border: '1px solid #E5E7EB',
                                                        borderRadius: '7px',
                                                        fontSize: '14px'
                                                    }}
                                                />
                                                <IoMdCalendar style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    fontSize: '20px'
                                                }} />
                                            </div>

                                            <div className="mt-3" style={{ position: 'relative' }}>
                                                <input
                                                    type="date"
                                                    placeholder="Até"
                                                    value={dateEnd}
                                                    onChange={(e) => setDateEnd(e.target.value)}
                                                    style={{
                                                        width: '100%',
                                                        padding: '13px',
                                                        paddingRight: '35px',
                                                        border: '1px solid #E5E7EB',
                                                        borderRadius: '7px',
                                                        fontSize: '14px'
                                                    }}
                                                />
                                                <IoMdCalendar style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    fontSize: '20px'
                                                }} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Filtros de Estados */}
                                    <Estados
                                        estadosData={estadosData}
                                        estadosSelecionados={estadosSelecionados}
                                        setEstadosSelecionados={setEstadosSelecionados}
                                    />

                                    {/* Filtros de Municípios */}
                                    <Municipio
                                        municipiosData={municipiosData}
                                        municipiosSelecionados={municipiosSelecionados}
                                        setMunicipiosSelecionados={setMunicipiosSelecionados}
                                    />

                                    {/* Filtros de Tipos de Impostos */}
                                    <Impostos
                                        impostosData={impostosData}
                                        impostosSelecionados={impostosSelecionados}
                                        setImpostosSelecionados={setImpostosSelecionados}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* TABELA */}
                        <div style={{ width: '73%' }}>
                            <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center mt-4 mb-4 w-100">
                                    <div className="pl-4 custom-normal" style={{ fontSize: '34px', fontWeight: '600' }}>Biblioteca de Normas</div>
                                </div>

                                <div className="bg-white" style={{ borderRadius: '1rem' }}>
                                    {/* LISTA DE NORMAS */}
                                    {loading && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            <FaSpinner className="spinner" />
                                        </div>
                                    )}
                                    {error && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            Erro: {error.message}
                                        </div>
                                    )}
                                    {!loading && !error && normasData.normas && normasData.normas.length > 0 ? normasData.normas.map((norma) => (
                                        <div className="d-flex flex-column align-items-end" key={norma.id}>
                                            <div className="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                                <div style={{ width: '45%' }}>
                                                    <div className="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>
                                                        {norma.nome}
                                                    </div>
                                                    <div
                                                        className="font-weight-light"
                                                        onClick={() => handleClick(norma.id)}
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            display: '-webkit-box',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: expandedId === norma.id ? 'unset' : 3,
                                                            maxHeight: expandedId === norma.id ? 'none' : '4.5em',
                                                            cursor: 'pointer',
                                                            transition: 'max-height 0.3s ease, padding-bottom 0.3s ease',
                                                            paddingBottom: expandedId === norma.id ? '2rem' : '1rem'
                                                        }}
                                                    >
                                                        {norma.descricao}
                                                    </div>
                                                </div>
                                                <div className="font-weight-light">{formatDate(norma.criado)}</div>

                                                <NormaLabel areaAtuacaoNome={norma.areaatuacao_nome} />

                                                <div className="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                    <div className="mr-3"><BsDownload /></div>
                                                    <div><LuArrowRight /></div>
                                                </div>
                                            </div>
                                            <div className="border-bottom" style={{ width: '97%' }}></div>
                                        </div>
                                    )) : (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>Nenhum dado disponível.</div>
                                    )}
                                    {/* FIM DA LISTA DE NORMAS */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Biblioteca;
