import { LuArrowRight } from "react-icons/lu";

function Seguranca() {
    const styles = {
        inputContainer: {
            position: 'relative',
            width: '100%'
        },
        input: {
            width: '100%',
            padding: '20px 10px 10px 10px',
            fontSize: '14px',
            border: '1px solid #E6E8EC',
            borderRadius: '4px',
            color: '#aaa',
            outline: 'none',
        },
        label: {
            position: 'absolute',
            top: '12px',
            left: '10px',
            color: '#aaa',
            fontSize: '10px',
            pointerEvents: 'none',
            transform: 'translateY(-50%)',
            transition: '0.2s ease all',
        },
    };

    return (
        <div>
            <div class="p-4 mb-4 bg-white shadow-sm d-flex justify-content-between" style={{ borderRadius: '1rem' }}>
                <div style={{ fontSize: '14px', fontWeight: '600' }}>Alterar Senha</div>
                <div className="d-flex flex-column align-items-start" style={{ width: '50%' }}>
                    <div className="d-flex align-items-center w-100" style={{ position: 'relative' }}>
                        <div style={styles.inputContainer}>
                            <input type="password" id="password" name="password"  style={styles.input} />
                            <label htmlFor="password" style={styles.label}>
                                Senha
                            </label>
                        </div>
                        <div className="custom-normal" style={{ position: 'absolute', right: '-4rem', color: '#6466F1' }}>Editar</div>
                    </div>
                </div>
                <div></div>
            </div>

            <div class="p-4 mb-4 bg-white shadow-sm" style={{ borderRadius: '1rem' }}>
                <div className="mb-4" style={{ fontSize: '14px', fontWeight: '600' }}>Autenticação 2 Fatores</div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="p-4 bg-white shadow-sm">
                        <div className="d-flex align-items-center">
                            <div style={{ width: '7px', height: '7px', borderRadius: '10px', backgroundColor: '#F14437', margin: '0 0.5rem 0 0' }}></div>
                            <div style={{ color: '#F14437' }}>off</div>
                        </div>
                        <div className="py-2" style={{ fontSize: '12px', fontWeight: '600' }}>Aplicativo autenticador</div>
                        <div style={{ fontSize: '12px', fontWeight: '400', color: '#6C7380' }}>Use um aplicativo autenticado (es: Google Autenticator)<br></br>para gerar um código de acesso</div>
                        <button className="d-flex justify-content-between align-items-center mt-3" style={{ border: '1px solid #449A86', color: '#449A86', padding: '0.5rem 1rem 0.5rem 1rem', background: 'transparent', borderRadius: '10px' }}>
                            <div className="mr-2" style={{ fontSize: '13px' }}>Configurar</div>
                            <LuArrowRight style={{ fontSize: '17px' }} />
                        </button>
                    </div>
                    <div className="p-4 mr-5 bg-white shadow-sm">
                        <div className="d-flex align-items-center">
                            <div style={{ width: '7px', height: '7px', borderRadius: '10px', backgroundColor: '#F14437', margin: '0 0.5rem 0 0' }}></div>
                            <div style={{ color: '#F14437' }}>off</div>
                        </div>
                        <div className="py-2" style={{ fontSize: '12px', fontWeight: '600' }}>Mensagem de Texto</div>
                        <div style={{ fontSize: '12px', fontWeight: '400', color: '#6C7380' }}>Use seu celular para receber uma mensagem com o<br></br>codigo de autenticação</div>
                        <button className="d-flex justify-content-between align-items-center mt-3" style={{ border: '1px solid #449A86', color: '#449A86', padding: '0.5rem 1rem 0.5rem 1rem', background: 'transparent', borderRadius: '10px' }}>
                            <div className="mr-2" style={{ fontSize: '13px' }}>Configurar</div>
                            <LuArrowRight style={{ fontSize: '17px' }} />
                        </button>
                    </div>
                </div>
            </div>

            <div class="bg-white shadow-sm d-flex flex-column justify-content-between" style={{ borderRadius: '1rem' }}>
                <div className="px-4 pt-4" style={{ fontSize: '14px', fontWeight: '600' }}>Histórico de Acesso</div>
                <div className="px-4" style={{ fontSize: '12px', fontWeight: '400', color: '#6C737F' }}>Sua atividade acesso recente.</div>
                <div className="mt-3 mb-3">
                    <div className="d-flex justify-content-between py-2 px-3 bg-light" style={{ fontSize: '13px', fontWeight: '600' }}>
                        <div style={{ width: '40%', fontSize: '11px' }}>TIPO DE LOGIN</div>
                        <div style={{ width: '40%', fontSize: '11px' }}>ENDEREÇO DE IP</div>
                        <div style={{ width: '20%', fontSize: '11px' }}>CLIENTE</div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-2 px-3" style={{ fontSize: '14px', fontWeight: '500', borderTop: '1px solid #F2F4F7' }}>
                        <div style={{ width: '40%', fontSize: '12px' }}>Login com senha<br></br>as 08:14 01/10/2024</div>
                        <div style={{ width: '40%', fontSize: '12px' }}>95.130.17.84</div>
                        <div style={{ width: '20%', fontSize: '12px' }}>Chrome, Windows 11</div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-2 px-3" style={{ fontSize: '14px', fontWeight: '500', borderTop: '1px solid #F2F4F7' }}>
                        <div style={{ width: '40%', fontSize: '12px' }}>Login com senha<br></br>as 15:54 01/10/2024</div>
                        <div style={{ width: '40%', fontSize: '12px' }}>95.130.17.84</div>
                        <div style={{ width: '20%', fontSize: '12px' }}>App, IOS 18.4</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Seguranca;
